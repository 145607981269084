import styled from "styled-components"
import media from "../../theme/breakpoints"

export const ResultsContainer = styled.div`
  display: grid;
  grid-gap: 2.8rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;

  ${media.lg`
    grid-gap: 2rem;
  `}

  ${media.sm`
    grid-template-columns: repeat(1, 1fr);
  `}
`

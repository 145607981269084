import React from "react"
import { useFlexSearch } from "react-use-flexsearch"
import * as queryString from "query-string"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import PostCard from "../../components/PostCard"
import Divider from "../../components/Divider"
import { useAllCategories } from "../../hooks/useAllCategories"
import { ResultsContainer } from "./style"

const SearchPosts = ({ data, location }) => {
  const { s: query } = queryString.parse(location.search)

  const results = useFlexSearch(
    query,
    data.localSearchBlog.index,
    JSON.parse(data.localSearchBlog.store)
  )

  const allCategories = useAllCategories()

  const postData = results.map(post => {
    const postCategories = post.categories.map(postCat => {
      const category = allCategories.filter(
        allCat => allCat.node.frontmatter.category_name === postCat
      )
      return { id: category[0].node.id, ...category[0].node.frontmatter }
    })

    return {
      ...post,
      categories: postCategories
    }
  })

  return (
    <Layout
      title={data.site.siteMetadata.title}
      location={location}
      type="main"
      description={data.site.siteMetadata.description}
      tagline={data.site.siteMetadata.tagline}
    >
      <h2>Search results for: {query}</h2>
      {postData.length > 0 ? (
        <ResultsContainer>
          {postData.map(node => {
            return <PostCard key={node.path} data={node} size="medium" />
          })}
        </ResultsContainer>
      ) : (
        <p style={{ textAlign: "center" }}>
          Sorry, couldn't find any posts matching this search.
        </p>
      )}
      <Divider />
    </Layout>
  )
}

export default SearchPosts

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        tagline
      }
    }
    localSearchBlog {
      index
      store
    }
  }
`
